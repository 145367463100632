<template>
  <div class="index_con">
    <div v-if="!system.isPhone">
      <!-- 顶部菜单栏 -->
      <top-menu-bar
        :curScroll="curScroll"
        @curIndex="curIndex"
        @goIndex="goIndex"
        @goLogon="goLogon"
      />
      <!-- 视频 -->
      <div class="none">
        <grop-intr />
      </div>
      <!-- 公司简介 -->
      <det1 />
      <!-- 底部 -->
      <bottom-det />
    </div>
    <!-- 
      wap端
     -->
    <div v-if="system.isPhone">
      <bar-wap
        class="wap_bar_menu"
        :curScroll="curScroll"
        @curIndex="curIndex"
        @goIndex="goIndex"
        @goLogon="goLogon"
      />
      <div class="none">
        <!-- <img
          src="@/assets/img/index/wapRectangle 247@2x.png"
          alt=""
          class="rela_img"
        /> -->
        <wap-grop-intr class="rela_img" />
      </div>
      <!-- 公司简介 -->
      <wap-det1 />
      <!-- 底部 -->
      <wap-bottom />
    </div>
  </div>
</template>
<script>
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
import BottomDet from "@/views/publicComponents/BottomDet";
import WapBottom from "@/views/publicComponents/WapBottom";
import BarWap from "@/views/publicComponents/BarWap";
const Det1 = () => import("./components/Det1").then(m => m.default || m);
const WapDet1 = () => import("./components/WapDet1").then(m => m.default || m);
const GropIntr = () =>
  import("../newIndex/components/GropIntr").then(m => m.default || m);
const WapGropIntr = () =>
  import("@/views/newIndex/components/WapGropIntr").then(m => m.default || m);
export default {
  name: "CompanyIntr",
  components: {
    TopMenuBar,
    BottomDet,
    WapBottom,
    BarWap,
    Det1,
    WapDet1,
    GropIntr,
    WapGropIntr
  },
  data() {
    return {
      curScroll: 0
    };
  },
  methods: {
    curIndex(index) {
      this.curScroll = index;
    },
    goIndex() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    goLogon() {
      this.$router.push("/login");
    }
  }
};
</script>
<style lang="less" scoped>
.none {
  // background: #c4c4c4;
  .rela_img {
    margin-top: 50px;
    width: 100%;
    display: block;
  }
}
</style>
